import dayjs from "dayjs";
import React from "react";

import {
  ExperienceReservation,
  PaymentBreakdown,
  PaymentLineItemEnum,
  PaymentLineItemRewards,
  PaymentLineItemTravelWalletCredit,
  PaymentLineItemUserCard,
} from "redmond";
import { ILabel, IValues } from "../FlightCard/helpers";
import {
  formatFiatValue,
  formatRewardsValue,
} from "../../../../../../utils/helpers";

export const CONFIRMATION = "Confirmation:";
export const EXPERIENCE_HEADER_TEXT = "Experience";
export const VIEW_DETAILS = "View details";
export const VIEW_LESS = "View less";
export const DATE_FORMAT = "ddd, MMM D";
export const CANCELED = "Canceled";
export const CANCELED_RES = "This reservation has been canceled.";
export const DETAILS = "Details";
export const TRAVELER = "Traveler";
export const TRAVELERS = (travelerNames: string[]) =>
  `Travelers (${travelerNames.length})`;

export const getHeaderText = (travelDate?: string) =>
  travelDate ? (
    <>
      <strong>{EXPERIENCE_HEADER_TEXT}</strong>{" "}
      <span className="separator">|</span>{" "}
      {dayjs(travelDate).format(DATE_FORMAT)}
    </>
  ) : (
    <strong>{EXPERIENCE_HEADER_TEXT}</strong>
  );

export const getExperienceInfoDetails = ({
  reservation,
}: ExperienceReservation) => {
  const { customerReservationId, experienceDetails, experienceSelection } =
    reservation;
  const { name, supplierInfo, logistics } = experienceDetails || {};
  const { travelDate, providerExperienceStartTime } = experienceSelection;
  const addresses = logistics?.startLocations[0].address.split(",");

  let title = "";

  if (addresses?.length === 3) {
    title = `${addresses[0].trim()}, ${addresses[1].trim().split(" ")[0]}`;
  } else if (addresses?.length === 4) {
    title = `${addresses[1].trim()}, ${addresses[2].trim().split(" ")[0]}`;
  }

  return {
    title,
    confirmationLabel: CONFIRMATION,
    confirmationCode: customerReservationId,
    startLabel: "",
    endLabel: "",
    startDate: dayjs(travelDate).format(DATE_FORMAT),
    endDate: dayjs(providerExperienceStartTime, "HH:mm").format("h:mm A"),
    subtitle: name || "",
    streetAddress: supplierInfo?.name || "",
    postalCode: supplierInfo?.phone || "",
    phoneNum: supplierInfo?.email || "",
  };
};

export const CANCEL_EXPERIENCE = "Cancel experience";
export const RESEND_CONFIRMATION = "Resend confirmation";
export const SHOW_VOUCHER = "Show voucher";

export const getLabelsFromPaymentBreakdown = (breakdown: PaymentBreakdown) => {
  const labels: ILabel = {
    cardLabel: null,
    rewardLabel: null,
  };
  breakdown.payments.forEach((payment) => {
    switch (payment.PaymentLineItem) {
      case PaymentLineItemEnum.UserCard:
        labels.cardLabel = (
          payment as PaymentLineItemUserCard
        ).cardNumberDisplay;
        break;
      case PaymentLineItemEnum.Rewards:
        labels.rewardLabel = (
          payment as PaymentLineItemRewards
        ).accountDisplayName;
        break;
      case PaymentLineItemEnum.TravelWallet:
        labels.rewardLabel = "Travel credit applied";
        break;
      default:
    }
  });
  return labels;
};

export const getValues = (breakdown: PaymentBreakdown) => {
  const values: IValues = {
    cardValue: null,
    rewardValue: null,
  };
  breakdown.payments.forEach((payment) => {
    switch (payment.PaymentLineItem) {
      case PaymentLineItemEnum.UserCard: {
        const cardAmt = (payment as PaymentLineItemUserCard).amount;
        values.cardValue = `${cardAmt.currency} $${formatFiatValue(
          cardAmt.amount
        )}`;
        break;
      }
      case PaymentLineItemEnum.Rewards: {
        const rewardAmt = payment as PaymentLineItemRewards;
        values.rewardValue = `${formatRewardsValue(
          rewardAmt.amount.rewardsPrice.value
        )} ${rewardAmt.amount.rewardsPrice.currency}`;
        break;
      }
      case PaymentLineItemEnum.TravelWallet: {
        const walletAmt = payment as PaymentLineItemTravelWalletCredit;
        values.rewardValue = `${walletAmt.amount.currency} $${formatFiatValue(
          walletAmt.amount.amount
        )}`;
        break;
      }
      default:
    }
  });
  return values;
};

// Experience Card Content
export const STARTING_POINT = "Starting point";
export const END_POINT = "End point";
export const STARTING_AND_END_POINT = "Starting & end point";
export const END_SAME_AS_START = "This activity ends at the starting point.";
export const VIEW_ITINERARY = "View itinerary";
export const CANCELLATION_POLICY = "Cancellation Policy";
export const VIEW_POLICY_DETAILS = "View policy details";
