import React, { MouseEventHandler } from "react";
import "./styles.scss";
import { MyTripsFilter } from "redmond";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import {
  ButtonWrap,
  CloseButtonIcon,
  MobilePopoverCard,
  ActionLink,
  useDeviceTypes,
  Icon,
  IconName,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { NoTripWithOfferResultsConnectorProps } from "./container";
import {
  PATH_FLIGHTS,
  PATH_DISRUPTION_PROTECTION_TERMS,
} from "../../../../../../utils/paths";
import { FLIGHT_DISRUPTION_INFO } from "./constants";

export interface INoResultsTripsWithOffersProps
  extends NoTripWithOfferResultsConnectorProps,
    RouteComponentProps {}
export const NoResultsTripsWithOffers = ({
  history,
  setTripsFilter,
  populateTripQueryParams,
}: INoResultsTripsWithOffersProps) => {
  const { matchesMobile } = useDeviceTypes();

  const [openModal, setOpenModal] = React.useState(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    setTripsFilter(MyTripsFilter.UPCOMING_TRIPS);
    populateTripQueryParams(history);
  };

  const handleViewTerms = () => {
    window.open(PATH_DISRUPTION_PROTECTION_TERMS, "_blank")?.focus();
  };

  const renderNoTripsResults = () => (
    <Box className="no-trips-with-offers-container">
      <Icon
        className="no-trips-with-offers-icon"
        name={IconName.DisruptionProtectionCircleBlue}
      />
      <Typography className="no-trips-with-offers-header">
        {FLIGHT_DISRUPTION_INFO.title}
      </Typography>
      <Typography className="no-trips-with-offers-title">
        {FLIGHT_DISRUPTION_INFO.conditionsTitle}
      </Typography>

      {FLIGHT_DISRUPTION_INFO.conditions.map((condition) => (
        <Box className="no-trips-with-offers-subtitle">
          <Typography className="no-trips-with-offers-subtitle-bullet">
            •
          </Typography>
          <Typography>{condition}</Typography>
        </Box>
      ))}
      <Typography className="no-trips-with-offers-title">
        {FLIGHT_DISRUPTION_INFO.actionsTitle}
      </Typography>
      {FLIGHT_DISRUPTION_INFO.actions.map((action) => (
        <Box className="no-trips-with-offers-subtitle">
          <Typography className="no-trips-with-offers-subtitle-bullet">
            •
          </Typography>
          <Typography>{action}</Typography>
        </Box>
      ))}
      <Typography
        onClick={handleViewTerms}
        className="no-trips-with-offers-footer"
      >
        {FLIGHT_DISRUPTION_INFO.termsLinkText}
      </Typography>
      <Button
        className="no-trips-with-offers-button"
        onClick={() => {
          history.push(PATH_FLIGHTS);
        }}
      >
        {FLIGHT_DISRUPTION_INFO.searchButtonText}
      </Button>
    </Box>
  );

  const DesktopModal = (
    <Dialog
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      className="desktop-no-trips-with-offers-popup"
      open={openModal}
      role="dialog"
      onClose={handleCloseModal}
    >
      <Box className="top-button-right">
        <ButtonWrap
          aria-label="Close"
          onClick={handleCloseModal as MouseEventHandler<HTMLButtonElement>}
        >
          <CloseButtonIcon />
        </ButtonWrap>
      </Box>
      {renderNoTripsResults()}
    </Dialog>
  );

  const MobileModal = (
    <MobilePopoverCard
      className="mobile-no-trips-with-offers-popup"
      open={openModal}
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={handleCloseModal}
        />
      }
    >
      {renderNoTripsResults()}
    </MobilePopoverCard>
  );

  return matchesMobile ? MobileModal : DesktopModal;
};
