import clsx from "clsx";
import {
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  GenericVirtualInterliningModal,
  Icon,
  IconName,
  MobilePopoverCard,
  MobilePriceDropProtectionImage,
  PriceDropProtectionImage,
} from "halifax";
import { uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import {
  AdultWithLapInfant,
  AssociatedPassenger,
  BookedFlightItineraryWithDepartureTime,
  Car,
  CarReservation,
  ExperienceReservation,
  ExperimentVariant,
  Flight,
  Home,
  HomesItinerary,
  Hotel,
  HotelItinerary,
  ItineraryEnum,
  ItineraryWithType,
  LodgingCollectionEnum,
  MISSED_CONNECTION_GUARANTEE_BODY,
  MyTripsModalTypes,
  PackageItinerary,
  Experience,
} from "redmond";

import {
  IPersonWithNameNumber,
  isMultiCityItinerary,
  MultiProvider,
} from "redmond/trips-module/itinerary";
import {
  ActiveExperiments,
  getExperimentVariant,
  useExperiment,
  useExperiments,
} from "../../../../../../context/experiments";
import { PassengersModalContent } from "../../../PassengersModalContent";
import { CancelCarModalContent } from "./components/CancelCarModalContent";
import { CancelFlightModalContent } from "./components/CancelFlightModalContent";
import { CancelGroundModalContent } from "./components/CancelGroundModalContent";
import { CancelHotelModalContent } from "./components/CancelHotelModalContent";
import { CarBasicInfoModalContent } from "./components/CarBasicInfoModalContent";
import { CarPaymentModalContent } from "./components/CarPaymentModalContent";
import { CfarFlightModalContent } from "./components/CfarFlightModalContent";
import { ChangeCarlModalContent } from "./components/ChangeCarlModalContent";
import { ChangeGroundModalContent } from "./components/ChangeGroundModalContent";
import { ChangeHotelModalContent } from "./components/ChangeHotelModalContent";
import { ChangeHotelModalContentV2 } from "./components/ChangeHotelModalContentV2";
import { CheckInFlightModalContent } from "./components/CheckInFlightModalContent";
import { ConfirmationModalContent } from "./components/ConfirmationModalContent";
import { ConfirmationModalHotelContent } from "./components/ConfirmationModalHotelContent";
import { ExchangeFlightModalContent } from "./components/ExchangeFlightModalContent";
import { FlightPaymentModalContent } from "./components/FlightPaymentModalContent";
import { HotelPaymentModalContent } from "./components/HotelPaymentModalContent";
import { ItinerarySummaryModalContent } from "./components/ItinerarySummaryModalContent";
import { LocationCarModalContent } from "./components/LocationCarModalContent";
import {
  RequestTravelerEditForm,
  RequestTravelerEditSuccess,
} from "./components/RequestTravelerEditForm";
import { ResendConfirmationModalContent } from "./components/ResendConfirmationModalContent";
import { ScheduleChangeModalContent } from "./components/ScheduleChangeModalContent";
import { SelfServeCancelFlightModalContent } from "./components/SelfServeCancelFlightModalContent";
import { ItinerariesModalConnectorProps } from "./container";

import { isWithin24Hours } from "../../../../utils";
import { CfarHotelModalContent } from "./components/CfarHotelModalContent";
import { HotelItemizedReceiptModalContent } from "./components/HotelItemizedReceiptModalContent";
import "./styles.scss";
import { getPropsForVariant } from "../../../../../common/VirtualIinterliningModal/helpers";
import { PATH_MISSED_CONNECTION_GUARANTEE } from "../../../../../../utils/paths";
import { HomePaymentModalContent } from "./components/HomePaymentModalContent";
import { CancelHomeModalContent } from "./components/CancelHomeModalContent";
import { HomeItemizedReceiptModalContent } from "./components/HomeItemizedReceiptModalContent/component";
import { PackagePaymentModalContent } from "./components/PackagePaymentModalContent";
import { ConfirmationModalPackageContent } from "./components/ConfirmationModalPackageContent";
import { CancelPackageHotelModalContent } from "./components/CancelPackageHotelModalContent";
import { ContactHostHomesModalContent } from "./components/ContactHostHomesModalContent";
import { ChangePackageHotelModalContent } from "./components/ChangePackageHotelModalContent";
import { ExchangePackageFlightModalContent } from "./components/ExchangePackageFlightModalContent";
import { PackageItemizedReceiptModalContent } from "./components/PackageItemizedReceiptModalContent";
import { ExperienceTravelersModalContent } from "./components/ExperienceTravelersModalContent";

export interface IItinerariesModalProps extends ItinerariesModalConnectorProps {
  isMobile?: boolean;
}

export const ItinerariesModal = ({
  isMobile,
  openModal,
  setOpenModal,
  setSelectedFlight,
  setSelectedHotel,
  tripsFilter,
  airportMap,
  airlineMap,
}: IItinerariesModalProps) => {
  const { type, selectedItinerary } = openModal;
  const [hideXButton, setHideXButton] = useState<boolean>(false);
  const [disableOnClose, setDisableOnClose] = useState<boolean>(false);
  const [usePartialScroll, setUsePartialScroll] = useState<boolean>(false);
  const expState = useExperiments();
  const hotelChangeExp = useExperiment(ActiveExperiments.SelfServeHotelChange);
  const groundCancelExp = useExperiment(
    ActiveExperiments.SelfServeGroundCancel
  );
  const groundChangeExp = useExperiment(
    ActiveExperiments.SelfServeGroundChange
  );

  const cfarGroup = getExperimentVariant(
    expState.experiments,
    ActiveExperiments.CFAR
  );
  const isCfarEnabled = useMemo(
    () => cfarGroup === ExperimentVariant.AVAILABLE,
    [cfarGroup]
  );

  const cfarHotelExp = getExperimentVariant(
    expState.experiments,
    ActiveExperiments.HOTELS_CFAR
  );
  const isHotelCfarEnabled = useMemo(
    () => cfarHotelExp === ExperimentVariant.AVAILABLE,
    [cfarHotelExp]
  );

  const cfarPremierCollectionExp = getExperimentVariant(
    expState.experiments,
    ActiveExperiments.PREMIER_COLLECTION_CFAR
  );
  const isPremierCollectionCfarEnabled = useMemo(
    () => cfarPremierCollectionExp === ExperimentVariant.AVAILABLE,
    [cfarPremierCollectionExp]
  );

  const onClose = () => {
    setHideXButton(false);
    setDisableOnClose(false);
    setOpenModal({ type: null, selectedItinerary: null });
  };

  const getDesktopModalClassName = (): string => {
    const desktopModalClasses = {
      [ItineraryEnum.Flight]: {
        [MyTripsModalTypes.ExchangeFlight]: "exchange-flight-modal",
        [MyTripsModalTypes.TravelersModal]: "travelers-modal new-design",
        [MyTripsModalTypes.PaymentModal]: "payment-modal",
        [MyTripsModalTypes.ConfirmationModal]: "confirmation-modal",
        [MyTripsModalTypes.ScheduleChange]: "schedule-change",
        [MyTripsModalTypes.CancelFlight]: "cancel-flight-modal",
        [MyTripsModalTypes.ChangeFlight]: "change-flight-modal",
        [MyTripsModalTypes.RequestTravelerEdit]: "request-traveler-edit-modal",
        [MyTripsModalTypes.RequestTravelerEditSuccess]:
          "request-traveler-edit-success-modal",
        [MyTripsModalTypes.CheckInFlight]: "checkin-flight-modal",
        [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
        [MyTripsModalTypes.SelfServeCancelFlight]:
          "self-serve-cancel-flight-modal",
        [MyTripsModalTypes.CfarFlight]: "cfar-flight-modal",
        [MyTripsModalTypes.VirtualInterlineMcp]: "virtual-interline-mcp-modal",
      },
      [ItineraryEnum.Hotel]: {
        [MyTripsModalTypes.TravelersModal]: "travelers-modal new-design",
        [MyTripsModalTypes.PaymentModal]: "hotel-payment-modal",
        [MyTripsModalTypes.CancelHotel]: "cancel-hotel-modal",
        [MyTripsModalTypes.ItemizedHotelReceipt]:
          "itemized-hotel-receipt-modal",
        [MyTripsModalTypes.ChangeHotel]: "change-hotel-modal",
        [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
        [MyTripsModalTypes.CfarHotel]: "cfar-hotel-modal",
        [MyTripsModalTypes.ConfirmationModal]: "confirmation-modal",
      },
      [ItineraryEnum.Car]: {
        [MyTripsModalTypes.DriversModal]:
          "drivers-modal travelers-modal new-design",
        [MyTripsModalTypes.CancelCar]: "cancel-car-modal",
        [MyTripsModalTypes.ChangeCar]: "change-car-modal",
        [MyTripsModalTypes.PaymentModal]: "payment-modal",
        [MyTripsModalTypes.CarConfirmation]: "car-confirmation-modal",
        [MyTripsModalTypes.DropOff]: "location-modal",
        [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
      },
      [ItineraryEnum.Home]: {
        [MyTripsModalTypes.PaymentModal]: "home-payment-modal",
        [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
        [MyTripsModalTypes.TravelersModal]: "travelers-modal new-design",
        [MyTripsModalTypes.CancelHome]: "cancel-home-modal",
        [MyTripsModalTypes.ItemizedHomeReceipt]: "itemized-home-receipt-modal",
        [MyTripsModalTypes.ContactHostHome]: "contact-host-homes-modal",
      },
      [ItineraryEnum.Package]: {
        [MyTripsModalTypes.PaymentModal]: "package-payment-modal",
        [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
        [MyTripsModalTypes.ConfirmationModal]: "confirmation-modal",
        [MyTripsModalTypes.CancelHotel]: "cancel-hotel-modal",
        [MyTripsModalTypes.ChangeHotel]: "change-hotel-modal",
        [MyTripsModalTypes.ExchangeFlight]: "exchange-flight-modal",
        [MyTripsModalTypes.SelfServeCancelFlight]:
          "self-serve-cancel-flight-modal",
        [MyTripsModalTypes.ItemizedPackageReceipt]:
          "itemized-package-receipt-modal",
      },
      [ItineraryEnum.Experience]: {
        [MyTripsModalTypes.CancelExperience]: "cancel-experience-modal",
        [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
        [MyTripsModalTypes.TravelersModal]: "experience-travelers-modal",
      },
    };
    return desktopModalClasses[(selectedItinerary as ItineraryWithType).type][
      type as string
    ];
  };

  const renderFlightModalContent = (
    flight: BookedFlightItineraryWithDepartureTime
  ) => {
    const { alone = [], withLapInfants = [] } =
      flight?.bookedItinerary?.passengers || {};
    const passengers: IPersonWithNameNumber[] = alone.map(
      (ap: AssociatedPassenger) => ({
        nameNumber: `${ap.nameNumber}`,
        ...ap.person,
      })
    );

    withLapInfants.forEach((awli: AdultWithLapInfant) => {
      passengers.push(
        { nameNumber: `${awli.adult.nameNumber}`, ...awli.adult.person },
        { nameNumber: `${awli.infant.nameNumber}`, ...awli.infant.person }
      );
    });
    const uniquePassengers = uniqBy(passengers, "id");

    const isMultiCity = isMultiCityItinerary(
      flight.bookedItinerary,
      airportMap
    );

    switch (type) {
      case MyTripsModalTypes.ExchangeFlight:
        return (
          <ExchangeFlightModalContent
            flight={flight}
            isMultiCity={isMultiCity}
          />
        );
      case MyTripsModalTypes.TravelersModal:
        return (
          <PassengersModalContent
            within24Hours={isWithin24Hours(flight.departureTime)}
            isReadOnly
            bookedItinerary={flight}
            setOpenModal={setOpenModal}
            locators={
              flight.bookedItinerary.travelItinerary.locators as MultiProvider
            }
            isMobile={isMobile}
            itineraryType={ItineraryEnum.Flight}
            passengers={uniquePassengers}
          />
        );
      case MyTripsModalTypes.PaymentModal:
        return <FlightPaymentModalContent flight={flight} />;
      case MyTripsModalTypes.RequestTravelerEdit:
        return (
          <RequestTravelerEditForm
            passengers={uniquePassengers}
            flight={flight}
            isMobile={isMobile}
            setOpenModal={setOpenModal}
          />
        );
      case MyTripsModalTypes.RequestTravelerEditSuccess:
        return (
          <RequestTravelerEditSuccess
            closeModal={onClose}
            isMobile={isMobile}
          />
        );
      case MyTripsModalTypes.ConfirmationModal:
        return <ConfirmationModalContent flight={flight} />;
      case MyTripsModalTypes.OutboundItinerary:
        return <ItinerarySummaryModalContent departure flight={flight} />;
      case MyTripsModalTypes.ReturnItinerary:
        return (
          <ItinerarySummaryModalContent flight={flight} departure={false} />
        );
      case MyTripsModalTypes.MulticityItinerary0:
        return (
          <ItinerarySummaryModalContent
            departure
            flight={flight}
            multicitySliceIndex={0}
          />
        );
      case MyTripsModalTypes.MulticityItinerary1:
        return (
          <ItinerarySummaryModalContent
            departure
            flight={flight}
            multicitySliceIndex={1}
          />
        );
      case MyTripsModalTypes.VirtualInterlineMcp:
        setHideXButton(true);
        return (
          <GenericVirtualInterliningModal
            image={
              isMobile
                ? MobilePriceDropProtectionImage
                : PriceDropProtectionImage
            }
            onClose={onClose}
            isMobile={isMobile}
            icon={<Icon name={IconName.FlightSearch} />}
            desktopTermsConditionURL={PATH_MISSED_CONNECTION_GUARANTEE}
            mobileTermsConditionCopy={MISSED_CONNECTION_GUARANTEE_BODY}
            {...getPropsForVariant("missedConnectionGuarantee")}
          />
        );
      case MyTripsModalTypes.MulticityItinerary2:
        return (
          <ItinerarySummaryModalContent
            departure
            flight={flight}
            multicitySliceIndex={2}
          />
        );
      case MyTripsModalTypes.MulticityItinerary3:
        return (
          <ItinerarySummaryModalContent
            departure
            flight={flight}
            multicitySliceIndex={3}
          />
        );
      case MyTripsModalTypes.MulticityItinerary4:
        return (
          <ItinerarySummaryModalContent
            departure
            flight={flight}
            multicitySliceIndex={4}
          />
        );
      case MyTripsModalTypes.ScheduleChange:
        return (
          <ScheduleChangeModalContent
            setSelectedFlight={setSelectedFlight}
            setSelectedHotel={setSelectedHotel}
            flight={flight}
            isMobile={!!isMobile}
          />
        );
      case MyTripsModalTypes.CancelFlight:
        return <CancelFlightModalContent flight={flight} />;
      case MyTripsModalTypes.CfarFlight:
        if (!isCfarEnabled) {
          return null;
        }
        return (
          <CfarFlightModalContent
            onClose={onClose}
            setHideXButton={setHideXButton}
            setDisableOnClose={setDisableOnClose}
            setUsePartialScroll={setUsePartialScroll}
            itineraryId={flight.bookedItinerary.id}
            flight={flight}
          />
        );

      case MyTripsModalTypes.CheckInFlight:
        return <CheckInFlightModalContent flight={flight} />;
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);

        return <ResendConfirmationModalContent itinerary={flight as Flight} />;
      case MyTripsModalTypes.SelfServeCancelFlight:
        return <SelfServeCancelFlightModalContent flight={flight} />;
      default:
        return null;
    }
  };

  const renderHotelModalContent = (hotelItinerary: HotelItinerary) => {
    switch (type) {
      case MyTripsModalTypes.TravelersModal:
        return (
          <PassengersModalContent
            isReadOnly
            setOpenModal={setOpenModal}
            isMobile={isMobile}
            itineraryType={ItineraryEnum.Hotel}
            passengers={hotelItinerary.reservation.guests}
          />
        );

      case MyTripsModalTypes.PaymentModal:
        return <HotelPaymentModalContent hotelItinerary={hotelItinerary} />;
      case MyTripsModalTypes.ConfirmationModal:
        return <ConfirmationModalHotelContent hotel={hotelItinerary} />;
      case MyTripsModalTypes.ItemizedHotelReceipt:
        if (!isMobile) setHideXButton(true);
        return (
          <HotelItemizedReceiptModalContent
            hotel={hotelItinerary}
            isMobile={isMobile}
            tripsFilter={tripsFilter}
          />
        );
      case MyTripsModalTypes.CancelHotel:
        return (
          <CancelHotelModalContent hotel={hotelItinerary} isMobile={isMobile} />
        );
      case MyTripsModalTypes.ChangeHotel:
        return hotelChangeExp ? (
          <ChangeHotelModalContentV2 hotel={hotelItinerary} />
        ) : (
          <ChangeHotelModalContent hotel={hotelItinerary} />
        );
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);
        return (
          <ResendConfirmationModalContent itinerary={hotelItinerary as Hotel} />
        );
      case MyTripsModalTypes.CfarHotel:
        const isLuxuryCollection =
          hotelItinerary.reservation.lodgingCollection ===
          LodgingCollectionEnum.Premier;
        if (
          (!isHotelCfarEnabled && !isLuxuryCollection) ||
          (!isPremierCollectionCfarEnabled && isLuxuryCollection)
        ) {
          return null;
        }
        return (
          <CfarHotelModalContent
            onClose={onClose}
            setHideXButton={setHideXButton}
            setDisableOnClose={setDisableOnClose}
            setUsePartialScroll={setUsePartialScroll}
            hotel={hotelItinerary}
          />
        );
      default:
        return null;
    }
  };

  const renderCarModalContent = (car: CarReservation) => {
    const { dropOff, pickUp } = car.bookResult;

    switch (type) {
      // case MyTripsModalTypes.CarConfirmation:
      //   return <CarConfirmationModalContent car={car} />;
      case MyTripsModalTypes.CancelCar:
        return groundCancelExp ? (
          <CancelGroundModalContent groundBooking={car} />
        ) : (
          <CancelCarModalContent car={car} />
        );
      case MyTripsModalTypes.BasicInfo:
        return <CarBasicInfoModalContent car={car} />;
      case MyTripsModalTypes.DropOff:
        return <LocationCarModalContent location={dropOff} isPickUp={false} />;
      case MyTripsModalTypes.PickUp:
        return <LocationCarModalContent location={pickUp} isPickUp />;
      case MyTripsModalTypes.ChangeCar:
        return groundChangeExp ? (
          <ChangeGroundModalContent groundBooking={car} />
        ) : (
          <ChangeCarlModalContent car={car} />
        );
      case MyTripsModalTypes.DriversModal:
        return (
          <PassengersModalContent
            isReadOnly
            isMobile={isMobile}
            setOpenModal={setOpenModal}
            itineraryType={ItineraryEnum.Car}
            passengers={[car.bookResult.driver]}
          />
        );
      case MyTripsModalTypes.PaymentModal:
        return <CarPaymentModalContent car={car} />;
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);

        return <ResendConfirmationModalContent itinerary={car as Car} />;
      default:
        return null;
    }
  };

  const renderHomeModalContent = (homeReservation: HomesItinerary) => {
    switch (type) {
      case MyTripsModalTypes.ItemizedHomeReceipt:
        return (
          <HomeItemizedReceiptModalContent
            homeItinerary={homeReservation}
            isMobile={isMobile}
          />
        );
      case MyTripsModalTypes.PaymentModal:
        return <HomePaymentModalContent homeItinerary={homeReservation} />;
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);
        return (
          <ResendConfirmationModalContent itinerary={homeReservation as Home} />
        );
      case MyTripsModalTypes.CancelHome:
        return <CancelHomeModalContent home={homeReservation} />;
      case MyTripsModalTypes.TravelersModal:
        return (
          <PassengersModalContent
            isReadOnly
            setOpenModal={setOpenModal}
            isMobile={isMobile}
            itineraryType={ItineraryEnum.Home}
            passengers={[homeReservation.reservation.primaryGuest]}
          />
        );
      case MyTripsModalTypes.ContactHostHome:
        return <ContactHostHomesModalContent home={homeReservation} />;
      default:
        return null;
    }
  };

  const renderPackageModalContent = (packageItinerary: PackageItinerary) => {
    switch (type) {
      case MyTripsModalTypes.PaymentModal:
        return (
          <PackagePaymentModalContent packageItinerary={packageItinerary} />
        );
      case MyTripsModalTypes.ConfirmationModal:
        return (
          <ConfirmationModalPackageContent
            packageItinerary={packageItinerary}
          />
        );
      // case MyTripsModalTypes.ResendConfirmation:
      //   setHideXButton(true);
      //   return (
      //     <ResendConfirmationModalContent
      //       itinerary={packageItinerary as Package}
      //     />
      //   );
      case MyTripsModalTypes.SelfServeCancelFlight:
        return (
          <SelfServeCancelFlightModalContent
            flight={packageItinerary.flight.itinerary}
            isPackageFlight
          />
        );
      case MyTripsModalTypes.CancelHotel:
        return (
          <CancelPackageHotelModalContent
            packageItinerary={packageItinerary}
            isMobile={isMobile}
          />
        );
      case MyTripsModalTypes.ChangeHotel:
        return (
          <ChangePackageHotelModalContent
            packageItinerary={packageItinerary}
            isMobile={isMobile}
          />
        );
      case MyTripsModalTypes.TravelersModal:
        return (
          <PassengersModalContent
            isReadOnly
            setOpenModal={setOpenModal}
            isMobile={isMobile}
            itineraryType={ItineraryEnum.Package}
            passengers={packageItinerary.hotel.itinerary.reservation.guests}
          />
        );
      case MyTripsModalTypes.OutboundItinerary:
        return (
          <ItinerarySummaryModalContent
            departure
            flight={packageItinerary.flight.itinerary}
          />
        );
      case MyTripsModalTypes.ReturnItinerary:
        return (
          <ItinerarySummaryModalContent
            flight={packageItinerary.flight.itinerary}
            departure={false}
          />
        );
      case MyTripsModalTypes.ExchangeFlight:
        return (
          <ExchangePackageFlightModalContent
            packageItinerary={packageItinerary}
          />
        );
      case MyTripsModalTypes.ItemizedPackageReceipt:
        if (!isMobile) setHideXButton(true);
        return (
          <PackageItemizedReceiptModalContent
            packageItinerary={packageItinerary}
            isMobile={isMobile}
            airlineMap={airlineMap}
          />
        );
      default:
        return null;
    }
  };

  const renderExperienceModalContent = (
    experienceReservation: ExperienceReservation
  ) => {
    switch (type) {
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);
        return (
          <ResendConfirmationModalContent
            itinerary={experienceReservation as Experience}
          />
        );
      case MyTripsModalTypes.TravelersModal:
        return (
          <ExperienceTravelersModalContent
            reservation={experienceReservation}
          />
        );
      default:
        return null;
    }
  };

  const renderModalContent = () => {
    switch ((selectedItinerary as ItineraryWithType).type) {
      case ItineraryEnum.Flight:
        return renderFlightModalContent(
          selectedItinerary as BookedFlightItineraryWithDepartureTime
        );
      case ItineraryEnum.Hotel:
        return renderHotelModalContent(selectedItinerary as HotelItinerary);
      case ItineraryEnum.Car:
        return renderCarModalContent(selectedItinerary as CarReservation);
      case ItineraryEnum.Home:
        return renderHomeModalContent(selectedItinerary as HomesItinerary);
      case ItineraryEnum.Package:
      case ItineraryEnum.PackageHotel:
      case ItineraryEnum.PackageFlight:
        return renderPackageModalContent(selectedItinerary as PackageItinerary);
      case ItineraryEnum.Experience:
        return renderExperienceModalContent(
          selectedItinerary as ExperienceReservation
        );
      default:
        return null;
    }
  };

  const ModalContent = useMemo(() => {
    if (selectedItinerary) {
      return renderModalContent();
    }

    return null;
  }, [selectedItinerary, type]);

  const renderDesktopModal = () => (
    <DesktopPopupModal
      className={clsx("my-trips-desktop-modal", getDesktopModalClassName(), {
        "partial-scroll": usePartialScroll,
      })}
      hideXButton={hideXButton}
      invisibleBackdrop={false}
      onClose={disableOnClose ? undefined : onClose}
      open
    >
      {ModalContent}
    </DesktopPopupModal>
  );

  const renderMobileModal = () => (
    <MobilePopoverCard
      centered
      open
      className="my-trips-mobile-popover"
      contentClassName="my-trips-mobile-popover-content"
      onClose={disableOnClose ? undefined : onClose}
      topRightButton={
        hideXButton ? undefined : (
          <ActionLink
            className="filter-close-button"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={onClose}
          />
        )
      }
    >
      {ModalContent}
    </MobilePopoverCard>
  );

  return selectedItinerary && type ? (
    <>{isMobile ? renderMobileModal() : renderDesktopModal()}</>
  ) : null;
};
