import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { BookingQuestionId, ExperienceReservation } from "redmond";

import styles from "./styles.module.scss";

export interface IExperienceTravelersModalContentProps {
  reservation: ExperienceReservation;
}

export const ExperienceTravelersModalContent = ({
  reservation,
}: IExperienceTravelersModalContentProps): JSX.Element | null => {
  const { bookingDetails } = reservation.reservation;

  if (!bookingDetails) return null;

  const { bookingQuestionAnswers } = bookingDetails;

  const travelers = useMemo(() => {
    const firstNames = bookingQuestionAnswers.filter(
      (answer) => answer.bookingQuestionId === BookingQuestionId.FullNamesFirst
    );
    const lastNames = bookingQuestionAnswers.filter(
      (answer) => answer.bookingQuestionId === BookingQuestionId.FullNamesLast
    );
    const agebands = bookingQuestionAnswers.filter(
      (answer) => answer.bookingQuestionId === BookingQuestionId.Ageband
    );

    return firstNames.map(({ answer }, idx) => ({
      fullName: `${answer} ${lastNames[idx].answer}`,
      ageband: agebands[idx].answer,
    }));
  }, [bookingQuestionAnswers]);

  return (
    <Box className={styles["modal-content-container"]}>
      <Typography variant="h4">Travelers ({travelers.length})</Typography>
      {travelers.map(({ fullName, ageband }) => (
        <Box className={styles["traveler-name-age-container"]}>
          <Typography className={styles.fullName}>{fullName}</Typography>
          <Typography variant="body2">{ageband}</Typography>
        </Box>
      ))}
    </Box>
  );
};
